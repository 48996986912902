<template>
  <div class="login">
    <div class="flex flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
      <transition name="fade" mode="out-in">
        <div v-if="showLoginForm">
          <LoginForm
            @toggle-form="toggleForm"
            @toggle-password-reset="togglePasswordReset"
          ></LoginForm>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-if="!showLoginForm && !showForgotPasswordForm">
          <CreateAccountForm
            @toggle-form="toggleForm"
            @toggle-password-reset="togglePasswordReset"
          ></CreateAccountForm>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-if="showForgotPasswordForm">
          <ForgotPasswordForm
            @toggle-form="toggleForm"
            @toggle-password-reset="togglePasswordReset"
          ></ForgotPasswordForm>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm.vue';
import CreateAccountForm from '@/components/forms/CreateAccountForm.vue';
import ForgotPasswordForm from '@/components/forms/ForgotPasswordForm.vue';

export default {
  name: 'Login',
  metaInfo: {
    title: 'Login | Recipeazy',
  },
  components: {
    LoginForm,
    CreateAccountForm,
    ForgotPasswordForm,
  },
  data() {
    return {
      showLoginForm: true,
      showCreateForm: false,
      showForgotPasswordForm: false,
      performingRequest: false,
    };
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
    },
    togglePasswordReset() {
      if (this.showForgotPasswordForm) {
        this.showLoginForm = true;
        this.showForgotPasswordForm = false;
      } else {
        this.showLoginForm = false;
        this.showForgotPasswordForm = true;
      }
    },
  },
};
</script>

<style></style>
