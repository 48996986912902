<template>
  <div class="recipe">
    <div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div v-if="recipeLoading">
        <!-- Recipe is loading -->
        <SkeletonBox />
      </div>
      <div v-else-if="!recipe.title && !recipeLoading">
        <!-- Recipe Not Found -->
        <div class="p-4 rounded-md bg-yellow-50">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fill-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium leading-5 text-yellow-800">Recipe Not Found</h3>
              <div class="mt-2 text-sm leading-5 text-yellow-700">
                <p>We can't seem to find that recipe. Please try a different one</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- The Recipe Card -->
        <div class="overflow-hidden bg-white rounded-lg shadow recipe">
          <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
            <!-- Content goes here -->
            <!-- We use less vertical padding on card headers on desktop than on body sections -->
            <h3 class="text-xl font-medium leading-tight text-gray-900">
              {{ recipe.title }}
            </h3>
            <p class="mt-1 text-sm text-gray-600">{{ recipe.description }}</p>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <!-- Content goes here -->
            <div class="flex flex-col items-start sm:flex-row">
              <div class="w-full mb-6 sm:w-1/2 sm:mb-0">
                <h3 class="text-lg font-medium leading-tight text-gray-900">Ingredients</h3>

                <div class="mt-4" :key="i" v-for="(ingredient, i) in recipe.ingredients">
                  <div class="flex items-start text-sm leading-tight">
                    <div class="flex items-center">
                      <!-- Zero-width space character, used to align checkbox properly -->
                      &#8203;
                      <!--<input id="option4" type="checkbox" class="w-4 h-4 text-blue-600 border-gray-400 form-checkbox" /> -->
                      <span
                        role="checkbox"
                        :tabindex="i"
                        :aria-checked="ingredient.completed"
                        @click="toggleIngredientCompleted(i)"
                        class="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 cursor-pointer group focus:outline-none"
                      >
                        <!-- On: "bg-blue-600", Off: "bg-gray-200" -->
                        <span
                          aria-hidden="true"
                          :class="{
                            'bg-blue-600': ingredient.completed,
                            'bg-gray-200': !ingredient.completed,
                          }"
                          class="absolute h-4 mx-auto transition-colors duration-200 ease-in-out rounded-full w-9"
                        ></span>
                        <!-- On: "translate-x-5", Off: "translate-x-0" -->
                        <span
                          aria-hidden="true"
                          :class="{
                            'translate-x-5': ingredient.completed,
                            'translate-x-0': !ingredient.completed,
                          }"
                          class="absolute left-0 inline-block w-5 h-5 transition-transform duration-200 ease-in-out transform bg-white border border-gray-200 rounded-full shadow group-focus:shadow-outline group-focus:border-blue-300"
                        ></span>
                      </span>
                    </div>
                    <div class="ml-3 font-medium text-gray-700">
                      <!--<span class="mr-2">{{ ingredient.amount }}</span>-->
                      <span>{{ ingredient.item }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full sm:w-1/2">
                <h3 class="text-lg font-medium leading-tight text-gray-900">Directions</h3>

                <div class="mt-4" :key="i" v-for="(direction, i) in recipe.directions">
                  <div class="flex items-start text-sm leading-tight">
                    <div class="flex items-center">
                      <!-- Zero-width space character, used to align checkbox properly -->
                      &#8203;
                      <!--<input
                        id="option4"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 border-gray-400 form-checkbox"
                      />-->
                      <span
                        role="checkbox"
                        :tabindex="i"
                        :aria-checked="direction.completed"
                        @click="toggleDirectionCompleted(i)"
                        class="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 cursor-pointer group focus:outline-none"
                      >
                        <!-- On: "bg-blue-600", Off: "bg-gray-200" -->
                        <span
                          aria-hidden="true"
                          :class="{
                            'bg-blue-600': direction.completed,
                            'bg-gray-200': !direction.completed,
                          }"
                          class="absolute h-4 mx-auto transition-colors duration-200 ease-in-out rounded-full w-9"
                        ></span>
                        <!-- On: "translate-x-5", Off: "translate-x-0" -->
                        <span
                          aria-hidden="true"
                          :class="{
                            'translate-x-5': direction.completed,
                            'translate-x-0': !direction.completed,
                          }"
                          class="absolute left-0 inline-block w-5 h-5 transition-transform duration-200 ease-in-out transform bg-white border border-gray-200 rounded-full shadow group-focus:shadow-outline group-focus:border-blue-300"
                        ></span>
                      </span>
                    </div>
                    <div class="ml-3 font-medium text-gray-700">
                      <span>{{ direction.text }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-4 border-t border-gray-200 sm:px-6">
            <!-- Content goes here -->
            <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
            <router-link
              v-if="user.uid === recipe.userId"
              :to="'/edit/' + recipe.id"
              class="relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white bg-blue-700 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-700 active:bg-blue-700"
            >
              Edit Recipe
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { db } from '@/services/firebase';
import { handleErrorCode, normalizeRecipe } from '@/util';
import SkeletonBox from '@/components/SkeletonBox.vue';

export default {
  name: 'Recipe',
  components: {
    SkeletonBox,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  beforeMount() {
    const { id } = this.$route.params;
    if (id) {
      this.fetchRecipe(id);
    }
  },
  metaInfo() {
    return {
      title: this.recipeLoading ? `Loading Recipe..` : `${this.recipe.title} | Recipeazy`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `${this.recipe.title} - ${this.recipe.description}`,
        },
      ],
    };
  },
  data() {
    return {
      recipe: {
        // need to set a default empty string here for the meta title and description
        title: '',
        description: '',
      },
      recipeLoading: false,
    };
  },
  methods: {
    toggleIngredientCompleted(i) {
      this.recipe.ingredients[i].completed = !this.recipe.ingredients[i].completed;
    },
    toggleDirectionCompleted(i) {
      this.recipe.directions[i].completed = !this.recipe.directions[i].completed;
    },
    async fetchRecipe(id) {
      this.recipeLoading = true;
      try {
        const queryRef = await db.collection('recipes').doc(id).get();
        this.recipe = normalizeRecipe(queryRef);

        this.recipeLoading = false;
      } catch (err) {
        this.recipeLoading = false;
        if (err.code) {
          this.errorMsg = handleErrorCode(err.code);
        } else {
          this.errorMsg = err;
        }
      }
    },
  },
};
</script>

<style></style>
