<template>
  <div class="404notfound">
    <div class="relative flex flex-col items-center justify-center w-screen h-screen bg-gray-900">
      <div class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-3xl font-bold leading-8 text-gray-900">Page Not Found</h3>
          <div class="max-w-xl mt-2 text-sm leading-5 text-gray-500">
            <p>Looks like you've followed a broken link or entered a URL that doesn't exist.</p>
          </div>
          <div class="mt-3 text-sm leading-5">
            <router-link
              to="/"
              class="block font-medium text-blue-600 transition duration-150 ease-in-out hover:text-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="inline-block text-blue-600"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  d="M11.9998836,4.09370803 L8.55809517,7.43294953 C8.23531459,7.74611298 8.23531459,8.25388736 8.55809517,8.56693769 L12,11.9062921 L9.84187871,14 L4.24208544,8.56693751 C3.91930485,8.25388719 3.91930485,7.74611281 4.24208544,7.43294936 L9.84199531,2 L11.9998836,4.09370803 Z"
                ></path>
              </svg>
              Go Back Home
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: 'Page Not Found | Recipeazy',
  },
};
</script>

<style></style>
