<template>
  <div class="forgot-password-form">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-3xl font-extrabold leading-9 text-center text-gray-900">
        Recover your password
      </h2>
      <p class="mt-2 text-sm leading-5 text-center text-gray-600 max-w">
        or
        <button
          @click="$emit('toggle-password-reset')"
          class="font-medium text-blue-700 transition duration-150 ease-in-out hover:text-blue-500 focus:outline-none focus:underline"
        >
          login to your account
        </button>
      </p>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="px-4 py-8 bg-white rounded-lg shadow sm:px-10">
        <transition name="fade" mode="out-in">
          <div v-if="errorMsg" class="p-4 mb-4 rounded-md bg-red-50">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="w-5 h-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium leading-5 text-red-800">Password Reset Error</h3>
                <div class="mt-2 text-sm leading-5 text-red-700">
                  <p>{{ errorMsg }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <FormulateForm
          name="forgot-password-form"
          v-model="forgotPasswordForm"
          :schema="schema"
          @submit="resetPassword"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/services/firebase';
import handleErrorCode from '@/util/handleErrorCode';
import useFormSchemas from '@/lib/formSchemas';
const { forgotPasswordFormSchema } = useFormSchemas();

export default {
  name: 'ForgotPasswordForm',
  data() {
    return {
      performingRequest: false,
      passwordResetSuccess: false,
      forgotPasswordForm: {
        email: '',
      },
      errorMsg: '',
      schema: forgotPasswordFormSchema,
    };
  },
  methods: {
    async resetPassword() {
      this.errorMsg = '';
      this.performingRequest = true;

      try {
        await auth().sendPasswordResetEmail(this.forgotPasswordForm.email);
        this.performingRequest = false;
        this.passwordResetSuccess = true;
        this.$formulate.reset('forgot-password-form');
      } catch (err) {
        this.errorMsg = err.code ? handleErrorCode(err) : err;
        this.performingRequest = false;
      }
    },
  },
};
</script>

<style></style>
