<template>
  <div class="faq">
    <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
      <h2 class="text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10">
        Frequently asked questions
      </h2>
      <div class="pt-6 mt-6 border-t-2 border-gray-400">
        <dl>
          <div class="md:grid md:grid-cols-12 md:gap-8">
            <dt class="text-base font-medium leading-6 text-gray-900 md:col-span-5">
              Whats different about this recipe site?
            </dt>
            <dd class="mt-2 md:mt-0 md:col-span-7">
              <p class="text-base leading-6 text-gray-500">
                Ever feel like every time you go searching for a recipe you are reading someone's
                life story instead of finding the actual ingredients? This site is strictly
                ingredients and directions. That is all.
              </p>
            </dd>
          </div>
          <div class="pt-6 mt-8 border-t border-gray-400 md:grid md:grid-cols-12 md:gap-8">
            <dt class="text-base font-medium leading-6 text-gray-900 md:col-span-5">
              How many recipes can I add?
            </dt>
            <dd class="mt-2 md:mt-0 md:col-span-7">
              <p class="text-base leading-6 text-gray-500">As many as you want! At no charge!</p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  metaInfo: {
    title: 'FAQ | Recipeazy',
  },
};
</script>
