<template>
  <div class="login-account-form">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-3xl font-extrabold leading-9 text-center text-gray-900">
        Sign in to your account!
      </h2>
      <p class="mt-2 text-sm leading-5 text-center text-gray-600 max-w">
        or
        <button
          @click="$emit('toggle-form')"
          class="font-medium text-blue-700 transition duration-150 ease-in-out hover:text-blue-500 focus:outline-none focus:underline"
        >
          Create an Account
        </button>
      </p>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="px-4 py-8 bg-white rounded-lg shadow sm:px-10">
        <transition name="fade" mode="out-in">
          <div v-if="errorMsg" class="p-4 mb-4 rounded-md bg-red-50">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="w-5 h-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium leading-5 text-red-800">Login Error</h3>
                <div class="mt-2 text-sm leading-5 text-red-700">
                  <p>{{ errorMsg }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="performingRequest" class="text-center">
            <LoadingSvg />
          </div>
        </transition>

        <FormulateForm name="login-form" v-model="loginForm" :schema="schema" @submit="login" />

        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 text-gray-500 bg-white">Or login with</span>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-3 mt-6">
            <div>
              <span class="inline-flex w-full rounded-md shadow-sm">
                <button
                  @click="signInWithSocial('google')"
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue"
                >
                  <svg
                    role="img"
                    class="h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Google icon</title>
                    <path
                      d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"
                    />
                  </svg>
                </button>
              </span>
            </div>

            <!--<div>
              <span class="inline-flex w-full rounded-md shadow-sm">
                <button
                  @click="signInWithSocial('twitter')"
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue"
                >
                  <svg
                    role="img"
                    class="h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Twitter icon</title>
                    <path
                      d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"
                    ></path>
                  </svg>
                </button>
              </span>
            </div>

            <div>
              <span class="inline-flex w-full rounded-md shadow-sm">
                <button
                  @click="signInWithSocial('facebook')"
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue"
                >
                  <svg
                    role="img"
                    class="h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Facebook icon</title>
                    <path
                      d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"
                    ></path>
                  </svg>
                </button>
              </span>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/services/firebase';
import LoadingSvg from '@/components/LoadingSvg.vue';
import handleErrorCode from '@/util/handleErrorCode';
import useFormSchemas from '@/lib/formSchemas';
const { loginFormSchema } = useFormSchemas();

export default {
  name: 'LoginForm',
  components: {
    LoadingSvg,
  },
  data() {
    return {
      performingRequest: false,
      loginForm: {
        email: '',
        password: '',
      },
      schema: loginFormSchema,
      errorMsg: '',
    };
  },
  methods: {
    async login() {
      this.errorMsg = '';
      this.performingRequest = true;

      try {
        await auth().signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password);
        this.performingRequest = false;
        this.$formulate.reset('login-form');
        this.$router.push('/');
      } catch (err) {
        this.errorMsg = handleErrorCode(err);
        this.performingRequest = false;
      }
    },
    async signInWithSocial(brand) {
      try {
        let provider;
        if (brand === 'google') {
          provider = new auth.GoogleAuthProvider();
        }
        //NOTE: app not supporting these yet
        if (brand === 'twitter') {
          provider = new auth.TwitterAuthProvider();
        }
        if (brand === 'facebook') {
          provider = new auth.FacebookAuthProvider();
        }
        await auth().signInWithPopup(provider);
        this.performingRequest = false;
        this.$router.push('/');
      } catch (err) {
        this.errorMsg = handleErrorCode(err);
        this.performingRequest = false;
      }
    },
  },
};
</script>

<style></style>
